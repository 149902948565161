import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const configs = {
    dev: {
        apiKey: process.env.REACT_APP_DEV_API_KEY,
        authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
        projectId: process.env.REACT_APP_DEV_PROJECT_ID,
        storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID
    },
    prd: {
        apiKey: process.env.REACT_APP_PRD_API_KEY,
        authDomain: process.env.REACT_APP_PRD_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_PRD_DATABASE_URL,
        projectId: process.env.REACT_APP_PRD_PROJECT_ID,
        storageBucket: process.env.REACT_APP_PRD_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_PRD_MESSAGING_SENDER_ID
    }
};

export const config = configs[process.env.REACT_APP_FIREBASE_ENV];

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = app.storage();
        this.functions = app.functions();
        this.europeanFunctions = app.app().functions('europe-west1');
    }

    // *** Auth API *** //

    doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

    doCreateUserProfile = (userId, email, firstName, lastName, initials) =>
        this.db.collection('users').doc(userId).set({email, firstName, lastName, initials});

    doCreateOrganization = async (name) => {
        const organizationRef = await this.db.collection('organizations').add({
            name,
            administrators: [this.auth.currentUser.uid]
        });
        const organizationId = organizationRef.id;
        const userProfileSnapshot = await this.authenticatedUserProfile().get();
        let administratorOf = userProfileSnapshot.data().administratorOf;
        if (!administratorOf) {
            administratorOf = {[organizationId]: name};
        } else {
            administratorOf[organizationId] = name;
        }
        await this.authenticatedUserProfile().set({administratorOf: administratorOf}, {merge: true});
    };

    authenticatedUserProfile = () => this.db.collection('users').doc(this.auth.currentUser.uid);

    organization = (orgId) => this.db.collection('organizations').doc(orgId);
    events = (orgId) => this.db.collection('organizations').doc(orgId).collection('events');
    event = (orgId, eventId) => this.db.collection('organizations').doc(orgId).collection('events').doc(eventId);
}

export default Firebase;