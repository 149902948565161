import * as React from 'react';

export interface Organization {
    id: string,
    name: string,
    administrators: [string]
}

export interface Event {
    id: string,
    name: string,
    timezone: string,
    city: string,
    country: string,
    countryCode: string,
    editors: [string],
    members: [string]
}

export interface UserProfile {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    initials: string,
    administratorOf: [Organization],
    memberOf: [Organization]
}

export interface SessionContext {
    userProfile: UserProfile|undefined,
    organization: Organization|undefined,
    event: Event|undefined,
    setUserProfile: (userProfile: UserProfile|undefined) => void,
    setOrganization: (organization: Organization|undefined) => void,
    setEvent: (event: Event|undefined) => void,
    setEverything: (userProfile: UserProfile, organization: Organization, event: Event|undefined) => void,
    clear: () => void,
    isAdministrator: () => boolean,
    isEditor: () => boolean
}

export const SESSION_CONTEXT_DEFAULT = {
    userProfile: undefined,
    organization: undefined,
    event: undefined,
    setUserProfile: (userEmail: UserProfile) => {},
    setOrganization: (organization: Organization) => {},
    setEvent: (event: Event) => {},
    setEverything: (userProfile: UserProfile, organization: Organization, event: Event|undefined) => {},
    clear: () => {},
    isAdministrator: () => false,
    idEditor: () => false
};

export const sessionContext = React.createContext(SESSION_CONTEXT_DEFAULT);