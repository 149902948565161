import React, {Fragment, lazy, Suspense, useContext} from "react";
import {CssBaseline, MuiThemeProvider} from "@material-ui/core";
import {Redirect, Route, Switch} from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";
import {useAuthState} from "react-firebase-hooks/auth";
import FirebaseContext from "./firebase-context";
import LinearProgress from "@material-ui/core/LinearProgress";
import {sessionContext} from "./context/session.context";
import {useSession} from "./hook/session.hook";

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

function App() {
    const session = useSession();
    const firebase = useContext(FirebaseContext);

    const [user, initialising] = useAuthState(firebase.auth);

    //console.log("user", user);
    //console.log("initialising", initialising);
    //console.log("error", error);
    return (
        <sessionContext.Provider value={session}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <GlobalStyles/>
                <Pace color={theme.palette.primary.light}/>
                {initialising ? <LinearProgress/> :
                    <Suspense fallback={<Fragment/>}>
                        <Switch>
                            {user && <Route path="/c">
                                <LoggedInComponent/>
                            </Route>}
                            <Route exact path="/">
                                <LoggedOutComponent/>
                            </Route>
                            <Redirect to="/"/>
                        </Switch>
                    </Suspense>}
            </MuiThemeProvider>
        </sessionContext.Provider>
    );
}

serviceWorker.register();

export default App;
