import React from "react";
import {Event, Organization, SessionContext, UserProfile} from '../context/session.context';
import useLocalStorage from 'react-use-localstorage';

export const useSession = (): SessionContext => {
    const [storedOrganizationId, setStoredOrganizationId] = useLocalStorage("ORGANIZATION_ID", "");
    const [storedEventId, setStoredEventId] = useLocalStorage("EVENT_ID", "");

    const [userProfile, setUserProfile] = React.useState<UserProfile | undefined>(undefined);
    const [organization, setOrganization] = React.useState<Organization | undefined>(undefined);
    const [event, setEvent] = React.useState<Event | undefined>(undefined);

    const updateOrganization = React.useCallback((newOrganization: Organization | undefined): void => {
        if (organization?.id !== newOrganization?.id) setOrganization(newOrganization);
        if (storedOrganizationId !== newOrganization?.id) setStoredOrganizationId(newOrganization?.id ?? "");
    }, [setStoredOrganizationId, storedOrganizationId, organization, setOrganization]);

    const updateEvent = React.useCallback((newEvent: Event | undefined): void => {
        if (event?.id !== newEvent?.id) setEvent(newEvent);
        if (storedEventId !== newEvent?.id) setStoredEventId(newEvent?.id ?? "");
    }, [setStoredEventId, storedEventId, event, setEvent]);

    const updateUserProfile = React.useCallback((newUserProfile: UserProfile | undefined): void => {
        if (userProfile?.id !== newUserProfile?.id) {
            setUserProfile(newUserProfile);
        }
    }, [userProfile, setUserProfile]);

    const updateEverything = React.useCallback((newUserProfile: UserProfile, newOrganization: Organization, newEvent: Event | undefined): void => {
        if (userProfile?.id !== newUserProfile?.id) {
            setUserProfile(newUserProfile);
        }
        if (organization?.id !== newOrganization.id) setOrganization(newOrganization);
        if (storedOrganizationId !== newOrganization.id) setStoredOrganizationId(newOrganization.id ?? "");
        if (event?.id !== newEvent?.id) setEvent(newEvent);
        if (storedEventId !== newEvent?.id) setStoredEventId(newEvent?.id ?? "");
    }, [
        userProfile,
        setUserProfile,
        organization,
        setOrganization,
        event,
        setEvent,
        setStoredEventId,
        setStoredOrganizationId,
        storedEventId,
        storedOrganizationId
    ]);

    const clearSession = React.useCallback(() => {
        setUserProfile(undefined);
        setOrganization(undefined);
        setEvent(undefined);
        setStoredOrganizationId("");
        setStoredEventId("");
    }, [setUserProfile, setOrganization, setEvent, setStoredOrganizationId, setStoredEventId]);

    const isAdministrator = React.useCallback((): boolean => {
        return userProfile !== undefined
            && organization !== undefined
            && userProfile.administratorOf.find(org => org.id === organization.id) !== undefined;
    }, [userProfile, organization]);

    const isEditor = React.useCallback((): boolean => {
        return isAdministrator()
            || (userProfile !== undefined
                && event !== undefined
                && event.editors.includes(userProfile.id));
    }, [isAdministrator, userProfile, event]);

    return {
        userProfile,
        organization,
        event,
        setUserProfile: updateUserProfile,
        setOrganization: updateOrganization,
        setEvent: updateEvent,
        setEverything: updateEverything,
        clear: clearSession,
        isAdministrator,
        isEditor
    };
};