import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import App from "./App";

import FirebaseContext from "./firebase-context";
import Firebase from "./firebase";

const firebase = new Firebase();

ReactDOM.render(
    <BrowserRouter>
        <FirebaseContext.Provider value={firebase}>
            <App/>
        </FirebaseContext.Provider>
    </BrowserRouter>,
    document.getElementById("root")
);
